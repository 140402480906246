import React from 'react';
import './CodeBranches.css';
import checkIcon from '../img/check-mark.svg';
import checkIconDisabled from '../img/check-mark-disabled.svg';

import { getUserId, getAPIHostname } from '../util/Utils'
import EmptyState from './EmptyState'

class CodeBranches extends React.Component {

	constructor(props) {
    	super(props);
    	this.state = {
    		selected: this.props.branch,
    		data: null,
    		gotResponse: false
    	}
	}

	fetchResults() {
		if (this.props.repo) {
			fetch(getAPIHostname() + "/branches?repo=" + this.props.repo + "&usecache")
	    		.then(res => res.text())
	    		.then(res => this.setState({"data": JSON.parse(res)}))
	    		.catch(err => this.setState({"data": {} }))
		} 
    }

	renderBranch(data) {
		let className = "BranchCard"
		if (this.state.selected === data.BranchName) {
			className = "BranchCard Selected"
		}
		return (
			<div className={className} 
				 onClick={() => { this.setState({selected: data.BranchName}); this.props.onBranchChange(data.BranchName) } } 
				 key={"branch-" + data.BranchName }>
				<div className="CardWrapper">
					<div className="BranchTitleWrapper">
						<div className="BranchTitle">
							<div className="circle"></div>
							<h4>{data.BranchName}</h4>
						</div>
						<img src={(this.state.selected === data.BranchName) ? checkIcon : checkIconDisabled}/>
					</div>
					<div className="LastCommit">
						<div className="CommitTimestamp">
							<div>Last Commit</div>
							<div className="highlight">{new Date(data.LastCommit.Author.When).toDateString()}</div>
						</div>
						<div className="CommitAuthor">						
							<div>{data.LastCommit.Author.Name}</div>
							<div>{data.LastCommit.Author.Email}</div>
						</div>
					</div>
					<div className="CommitMessage">
					{data.LastCommit.Message}
					</div>
				</div>
			</div>
		)		
	}

	isLoading() {
		return (this.state.data == null)
	}

	render() {

		let entries = []

		if(this.state.data == null || this.props.repo != this.state.data.RepoUrl) {
  			this.fetchResults();
  		} else {
			if (this.state.data && this.state.data.Branches) {
				let branches = this.state.data.Branches
				for (let i=0; i<branches.length; i++) {
					entries.push(this.renderBranch(branches[i]))
				}
			}
  		}

		let style = this.isLoading() ? {"animationName": "loading"} : {}

		if (!this.isLoading()) {
			return (<div className="CodeBranches" style={style}>
				{entries}
				</div>
				)
		} else {
			if (!this.props.repo) {
				return <EmptyState emptyAccount={true}/>
			} else {
				return <EmptyState loadingTitle={true}/>				
			}
		}

	}

}

export default CodeBranches;