import React from 'react';
import './ContributorGraph.css';

import { getUserId, getAPIHostname } from '../util/Utils'
import EmptyState from './EmptyState'

class ContributorGraph extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
      		data: this.getInitStateData(),
      		activeFiles: []
    	}

		this.repo = props.repo;
		this.months=["jan","feb", "mar", "apr", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]
	}

	getInitStateData() {
		return {
      			"RepoUrl": "",
      			"Contributors": [],
      			"MonthlyIndex": {}
      		}
	}

	fetchResults() {
		if (this.props.repo) {
			fetch(getAPIHostname() + "/contributors?repo=" + this.props.repo + "&branch=" + this.props.branch + "&usecache")
	    		.then(res => res.text())
	    		.then(res => this.setState({"data": JSON.parse(res)}))
	    }
	}

	hasActivity(contributors, monthlyIndex) {
		let result = false
		for (let i=0; i<contributors.length; i++) {
			for (let j=0; j<monthlyIndex[contributors[i]].length; j++) {
				if(monthlyIndex[contributors[i]][j] != null) {
					result = true
					break
				}
			}
		}
		return result
	}

	renderFileList() {
		let entries = []
		let files = this.state.activeFiles
		if (files != null && files.length > 0) {
			for (let i=0; i<files.length; i++) {
				entries.push(<div>{files[i].FileName}</div>)	
			}
		}
		return (<div className="FileList">
			{entries}
			</div>
			)
	}

	isLoading() {
		return this.props.repo && this.props.repo.length > 0 && this.props.repo !== this.state.data.RepoUrl;
	}

	getHeader() {
		let entry = []
		entry.push(<div/>)
		for (let i=0; i<25; i++) {
			entry.push(<div className="ContributorGraphLabel" key={"label-" + i}>{this.months[(7+i)%this.months.length]}</div>)
		}
		return entry
	}

	render() {

		let contributors = this.state.data.Contributors		
		if(this.props.repo !== this.state.data.RepoUrl) {
			contributors = [];
  			this.fetchResults();
  		}

  		let style = this.isLoading() ? {"animationName": "loading"} : {}

		let entries = []

		
		for (let i=0; i<Math.min(50,contributors.length); i++) {
			entries.push(<div className="ContributorName" key={"contributor-" + i}>{contributors[i]}</div>)
			for(let j=0; j<25; j++) {
				let countEntry = this.state.data.MonthlyIndex[contributors[i]][j]
				let volume = (countEntry != null) ? countEntry.length : 0;
				let style = {"backgroundColor": "rgba(254,188,47," + (Math.min(volume/10,1)) + ")"}
				entries.push(<div className="ContributorActivityEntry" style={style} key={"contributor-" + i + "-month-" + j} 
								  onClick={() => this.setState({"activeFiles": countEntry})}></div>)
			}
		}

		if (!this.isLoading()) {
			return (
				<div className="ContributorGraphMeta">
					<div className="ContributorGraphContainer" style={style}>
						<div className="ContributorGraph">
						{this.getHeader()}
						{entries}
						</div>
					</div>
					{this.renderFileList()}
				</div>)
		} else {
			return <EmptyState loadingTitle={true} />			
		}
	}

}




export default ContributorGraph;