import React, { useState, useEffect } from 'react';
import './Home.css';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Header } from 'semantic-ui-react';
import logo from './img/codeweb-logo-full.svg';
import hero from './img/hero.svg';

import { useCookies } from 'react-cookie';
import {reactLocalStorage} from 'reactjs-localstorage';

const Login = () => {
  const { authState, authService } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [cookies, setCookie] = useCookies(['userinfo']);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      authService.getUser().then((info) => {
        setUserInfo(info);
        setCookie('userinfo', info, { path: '/' });
        reactLocalStorage.setObject('userinfo',info);
      });
    }
  }, [authState, authService]); // Update if authState changes

  const login = async () => {
    authService.login('/dashboard');
  };


  const logout = async () => {
    authService.logout('/');
  };

  if (authState.isPending) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <div>
      <div className="Header">
        <div className="HomeLogo">
        <img src={logo} height="30px"/>
        </div>

        <div className="UserLogin">
          { authState.isAuthenticated && !userInfo
          && <div onClick={logout}>Loading user information...</div>}

          {authState.isAuthenticated && userInfo
          && (
          <div className="LoginBar">
            <a href="/dashboard"><strong>{userInfo.name}'s</strong> Dashboard</a>          
            <div className="LoginButton" onClick={logout}>Logout</div>
          </div>
          )}

          {!authState.isAuthenticated
          && (
          <div>
            <div className="LoginButton" onClick={login}>Login</div>
          </div>
          )}
        </div>
      </div>
      <div className="Hero">
        <div className="HeroMessage">
          <h2>Visualize your codebase.</h2>
          <p>Take control of your development ecosystem.</p>
          <a href="http://eepurl.com/hlGFBf"><div className="SignUpButton">Sign up for beta</div></a>
          <img src={hero}/>          
        </div>
      </div>
    </div>

  );
};
export default Login;