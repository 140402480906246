import React from 'react';
import './ImportModal.css';

import closeIcon from '../img/close.svg';
import importIcon from '../img/import.svg';
import importProgressIcon from '../img/import-progress.svg';

import { getUserId, getAPIHostname, getRandomWord } from '../util/Utils'
import Timer from 'react-compound-timer';

class ModalTitle extends React.Component {
  render() {
    return (
    	<React.Fragment>
			<div className="TitleContainer">
				<img src={importIcon}/>
				<div>
	    			<h1>{this.props.title}</h1>
	    			<p>{this.props.subtitle != null ? this.props.subtitle : "Import your source code repository to CodeWeb"}</p>
	    		</div>
			</div>
			<img src={closeIcon} onClick={() => this.props.closeClick()} className="close-icon"/>
		</React.Fragment>
      )
  }
}

class NewProjectForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			preload: this.props.repoUrl,
			repoUrl: this.props.repoUrl ? this.props.repoUrl : '',
			tocChecked: false
		}
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		this.props.onSetRepoUrl(event.target.value);
    	this.setState({repoUrl: event.target.value});
  	}

	importClickHandler() {
		if (this.state.repoUrl.length > 0 && this.state.repoUrl.startsWith("https://github.com") || this.state.repoUrl.startsWith("github.com")) {
			fetch(getAPIHostname() + "/scorecard?repo=" + this.state.repoUrl + "&usecache")
	        	.then(res => res.text())
	        	.then(res => this.props.onAPIResponse(res))
	        	.then(this.props.onNextClick())
        		.catch(err => this.props.onErrorResponse(err))
	    } else {
	    	console.log("bad url")
	    }
	}

	getAPIResponse() {
		return this.state.apiResponse
	}

	render() {
		return (
				<div className="ImportNewProject">
	    			<ModalTitle title="Import New Project" closeClick={() => this.props.closeClick()}/>

	    			<ul>
	    				<li>From Github URL</li>
	    				<li>Search Github</li>
	    				<li>Direct Upload</li>
	    			</ul>

	    			{ this.state.preload.length > 0 ?
	    				<input type="text" value={this.state.preload} onChange={this.handleChange} autoFocus></input>
		    			:
						<input type="text" placeholder="Enter project's github url" onChange={this.handleChange} autoFocus></input>		    			
		    		}

	    			<p>Your project will be pulled from a given repository and imported into the CodeWeb environment. 
	    			All changes to the original repo will be automatically reflected in CodeWeb.</p>

					<div className="TOC">
						<input type="checkbox" checked={this.state.tocChecked} onChange={() => this.setState({tocChecked: !this.state.tocChecked})}></input>
						<div>I accept the CodeWeb Terms and Conditions</div>
					</div>

					<StepController onNextClick={() => { this.importClickHandler() }  } back={false} />

	    		</div>

			)
	}
}


class ImportProgressForm extends React.Component {
	render() {
		let style = !this.props.responseReceived ? {"animationName": "importing"} : {}
		return (
			<div className="ImportNewProject">
				<ModalTitle title="Import in progress..." closeClick={() => this.props.closeClick()}/>
					<div>
						{!this.props.errorOccured ?
							<div className="progress-bar-container">
								<div className="progress-bar" style={style}></div>
								<Timer initialTime={2} timeToUpdate={100} startImmediately={true}>
									{({ start, resume, pause, stop, reset, timerState, getTime }) => (
										<h5>{getRandomWord()}</h5>
									)}
								</Timer>
							</div>
						: <div className="ErrorMessage">An error occured during repo import</div>
						}
				</div>
			</div>
		)
	}
}



class InputConfigurationForm extends React.Component {
	render() {
		return (
				<div className="ImportNewProject">
	    			<ModalTitle title="Import Configuration" closeClick={() => this.props.closeClick()}/>

	    			<h4>Chose a unique project name</h4>

					<StepController onNextClick={() => this.props.onNextClick()} onBackClick={() => this.props.onBackClick()} back={true} />

	    		</div>

			)
	}
}


class ImportCompleteForm extends React.Component {
	render() {
		let data = JSON.parse(this.props.data)
		return (
			(data != null &&
				<div className="ImportNewProject">
	    			<ModalTitle title="Import Complete" subtitle="Project scorecard metrics are now ready" closeClick={() => this.props.closeClick()}/>

	    			<h3>Import Summary</h3>

	    			<ul>
		    			<li>
			    			<h5>Imported repository</h5>
							<h4>{data.RepoUrl}</h4>
						</li>
		    			<li>
			    			<h5>Project name</h5>
							<h4>{data.ProjectName}</h4>
						</li>
						<li>
			    			<h5>Programming Language</h5>
							<h4>{data.Language}</h4>
						</li>
						<li>
			    			<h5>Project description</h5>
							<h4>{data.ProjectDescription}</h4>
						</li>
						<li>
			    			<h5>License</h5>
							<h4>{data.License}</h4>
						</li>
					</ul>

					<StepController onNextClick={() => this.props.onNextClick()} onBackClick={() => this.props.onBackClick()} back={true} />

	    		</div>
	    	)
			)
	}
}

class StepController extends React.Component {
	render() {
		return (
			<div className="StepController">
				<div className="ControlContainer">
					<div className="Button back-button" onClick={() => this.props.onBackClick()} style={{"visibility": this.props.back ? "visible" : "hidden"}}>Back</div>
					<div className="Button forward-button" onClick={() => this.props.onNextClick()}>Next</div>
				</div>
			</div>
			)
	}
}

class ImportModal extends React.Component {

	constructor(props) {
    	super(props);
    	this.state = {
    		step: 0,
    		repoUrl: this.props.repoUrl ? this.props.repoUrl : '',
    		apiResponse: null,
    		errorResponse: null
    	}
	}

	getCurrentStep() {
		switch(this.state.step) {
			case 0:
				return <NewProjectForm 
						closeClick={() => this.props.closeClick()} 
						onNextClick={() => this.setState({step: this.state.step + 1}) } 
						onSetRepoUrl={(repo) => this.setState({repoUrl: repo})}
						onAPIResponse={(data) => this.setState({apiResponse: data, step: this.state.step + 1}) }
						onErrorResponse={(error) => this.setState({errorResponse: error})}
						repoUrl={this.props.repoUrl ? this.props.repoUrl : ''}
						back={false}
						/>
			case 1:
				return <ImportProgressForm
							onNextClick={() => this.setState({step: this.state.step + 1}) } 
							closeClick={() => this.props.closeClick()}
							responseReceived={this.state.apiResponse != null} 
							errorOccured={this.state.errorResponse != null}
						/>
			case 2:
				return <ImportCompleteForm 
							data={this.state.apiResponse}
							repoUrl={this.state.repoUrl}
							closeClick={() => this.props.closeClick()} 
							onNextClick={() => {
												this.props.repoChange(this.state.repoUrl); 
												this.props.closeClick(this.state.repoUrl)
											}} 
							onRepoChange={(repo) => this.props.onRepoChange(repo)}
							onBackClick={() => this.setState({step: this.state.step - 2}) } 
							back={true}
							/>
			default:
				return <NewProjectForm closeClick={() => this.props.closeClick()} onNextClick={() => this.setState({step: this.state.step + 1}) } />
		}
	}


	render() {
    	return (
    		<div className="ImportModal">
    			{this.getCurrentStep()}
    		</div>
	)}

}


export default ImportModal;
	