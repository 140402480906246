import React from 'react';
import './CodeViewer.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco, hybrid } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { getSearchHostname } from '../util/Utils';

class CodeViewer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      source: ""
    }
  }

  fetchResults() {
      let source = this.props.source
      fetch(getSearchHostname() + "/_search?q=\"" + source + "\"")
          .then(res => res.text())
          .then(res => this.setState({"data": JSON.parse(res), "source": source }))
          .catch(err => console.log(err))
  }

  render() {

    let codeString = '';
    let hasResults = true;

    if (!this.state.data || (this.props.source != this.state.source)) {
      this.fetchResults()
    } else if (this.state.data.hits.hits.length > 0) {
      codeString = decodeURIComponent(this.state.data.hits.hits[0]._source.payload).replaceAll("+", " ")
    } else {
      hasResults = false
    }

    return (<div className="CodeViewerContainer" style={this.props.standalone ? {"margin": "50px"} : {}}>
        <div className="CodeViewer">
        { hasResults ? 
          <SyntaxHighlighter language="javascript" style={hybrid}>
                {codeString}
          </SyntaxHighlighter>
        : 
          <div className="NoFileFound">Can't find file in the master index</div>
        }
        </div>
      </div>)
  }

}


export default CodeViewer;