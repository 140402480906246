import React from 'react';
import './SearchResults.css';
import addIcon from '../img/add.svg';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { hybrid } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import {reactLocalStorage} from 'reactjs-localstorage';

import { getSearchHostname } from '../util/Utils';
// import mockGithubResponse from '../data/searchresults.json';
import EmptyState from './EmptyState';

class SearchResults extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: null,
      reqSource: "local",
      localResults: null,
      githubResults: null
    }
  }

  appendQuery(data, query) {
    data["query"] = query
    return data
  }

  fetchResults() {
    if (this.props.query && this.state.reqSource === "local") {
      let query = this.props.query
      fetch(getSearchHostname() + "/_search?q=" + encodeURIComponent(this.props.query))
          .then(res => res.text())
          .then(res => this.setState({"localResults": this.appendQuery(JSON.parse(res), query) }))
          .catch(err => console.log(err))
      } else {
        let query = this.props.query
        fetch("https://api.github.com/search/repositories?q=" + query, {
              headers: {'Authorization': 'token b6a856c093cb1e291342214fb69b23ffa80a4973'}})
            .then(res => res.text())
            .then(res => this.setState({"githubResults": this.appendQuery(JSON.parse(res), query) }))
            .catch(err => console.log(err))       
      }
  }

  addSnippet(payload) {
    if (!reactLocalStorage.getObject('snippets').queue) {
      reactLocalStorage.setObject('snippets', {'queue': []});
    }
    let data = reactLocalStorage.getObject('snippets')
    data.queue.push(payload)
    reactLocalStorage.setObject('snippets', data)
  }

  isLoadingGithub() {
    return (this.state.reqSource === "github" && 
             (!this.state.githubResults || (this.props.query !== this.state.githubResults.query)))
  }

  isLoadingLocal() {
      return (this.state.reqSource === "local" && 
               (!this.state.localResults || (this.props.query !== this.state.localResults.query)))
  }

  render() {
    let localResults = []
    let githubResults = []
    let resultCount = 0



      if ((this.state.reqSource === "local" && (!this.state.localResults || (this.props.query !== this.state.localResults.query)))
         || (this.state.reqSource === "github" && (!this.state.githubResults || (this.props.query !== this.state.githubResults.query)) )) {
      this.fetchResults()
    } else if (this.state.reqSource === "local") {
      let hits = this.state.localResults.hits.hits
      resultCount = this.state.localResults.hits.total.value
      for (let i=0; i<hits.length; i++) {
        let repo = hits[i]["_source"]
        try {
          localResults.push(<div className="SearchResultEntry" key={"search-result-" + i}>
                        <a href={"/dashboard?active=code&source=" + repo.title}><h3>{repo.title}</h3></a>
                        <h5>{repo.repo}</h5>
                        <div className="AddSnippet" onClick={() => this.addSnippet(repo) }><img src={addIcon} alt=""/></div>
                        <SyntaxHighlighter language="go" style={hybrid}>{decodeURIComponent(repo.payload).replaceAll("+", " ").substring(0,2048)}</SyntaxHighlighter>
                       </div>)
        } catch (err) {
          console.log("error parsing search response")
        } 
      }
    } 

    if (this.state.reqSource === "github" && this.state.githubResults) {
        let data = this.state.githubResults
        resultCount = data.total_count
        for (let i=0; i<data.items.length; i++) {
          githubResults.push(<div className="GithubSearchResult">
              <div className="Content">
                <h3>{data.items[i].name}</h3>
                <h5>{data.items[i].full_name}</h5>
                <p>{data.items[i].description}</p>
              </div>
                <div className="button" 
                     onClick={() => window.open("/project?active=import&selected=scorecard&repo=" + data.items[i].clone_url, "_self")}>Add to CodeWeb</div>

            </div>)
        }
    }

    return (
      <div className="SearchResultsContainer">
        <div className="SearchResults">
          {this.state.localResults && 
            <React.Fragment>
              <h3><strong>{this.props.query}</strong> search results</h3>
              <div className="SearchNav">
                <div className="SearchNavTab"
                     style={this.state.reqSource === "local" ? {"borderBottom": "1px solid #f05c8a"} : {}} 
                     onClick={() => this.setState({"reqSource": "local"})}>
                  <h6>Local Data</h6>
                </div>
                <div className="SearchNavTab" 
                     style={this.state.reqSource === "github" ? {"borderBottom": "1px solid #f05c8a"} : {}}
                     onClick={() => this.setState({"reqSource": "github"})}>
                  <h6>Github API</h6>
                </div>
              </div>
              <div className="QuerySummary">
                  <h5><strong>{resultCount}</strong> matches</h5>           
              </div>
              
              {(this.state.reqSource === "local" && this.state.localResults) &&
                <div className="ResultsList">
                {localResults}
                </div>
              }
              {this.isLoadingLocal() &&
                <EmptyState loadingTitle={true} />
              }

              {!this.isLoadingGithub() &&
                <div className="GithubSearchGrid">
                  {githubResults}
                </div>
              }
              {this.isLoadingGithub() &&
                <EmptyState loadingTitle={true} />
              }

              {(this.state.reqSource === "local" && this.state.localResults && (resultCount === 0)) &&
                <div className="NoResults"><h5>No Results Found</h5></div>
              }

            </React.Fragment>
          }
        </div>
      </div>)
  }

}

export default SearchResults;