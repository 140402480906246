import React from 'react';
import './MasterFrame.css';
import dashboardLogo from './img/dashboard.svg';
import projectLogo from './img/project.svg';
import snipetsLogo from './img/snippets.svg';
import teamLogo from './img/team.svg';
import searchIcon from './img/search.svg';
import notificationsIcon from './img/notifications.svg';
import userIcon from './img/user.svg';
import dropdownIcon from './img/down.svg';

import ProjectScorecard from './components/ProjectScorecard'
import CodeBranches from './components/CodeBranches'
import CodeGraph from './components/CodeGraph'
import ContributorGraph from './components/ContributorGraph'
import Dashboard from './components/Dashboard'
import Snippets from './components/Snippets'
import SearchResults from './components/SearchResults'
import ImportModal from './components/ImportModal'
import CodeViewer from './components/CodeViewer'
import EmptyState from './components/EmptyState'
import ImportProject from './components/ImportProject'

import downIcon from './img/arrowdown.svg';
import downIconWhite from './img/arrowdown-white.svg';
import forkIcon from './img/fork.svg';
import shareIcon from './img/share.svg';

import queryString from 'query-string'
import { getUserId, getUserInfo, getAPIHostname } from './util/Utils'
import { useOktaAuth } from '@okta/okta-react';


// Header 

class CodeWebHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      
      <div className="CodeWebHeader">
        <a href="/"><div className="CodeWebLogo"></div></a>
        <div className="HeaderControl">
          <WebsiteSearch onSearchQuery={(query) => this.props.onSearchQuery(query)} />
          <div className="UserHeader">
            <ImportProject onClick={() => this.props.onImportClick()} />
            <HeaderNotifications />
            <UserPreferences />
          </div>
        </div>
      </div>

      )
  }
}

class WebsiteSearch extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchQuery: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  handleChange(event) {
    this.setState({searchQuery: event.target.value});
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.setState({searchQuery: ""})
      this.props.onSearchQuery(this.state.searchQuery)
    }
  }

  render() {
    return (
      <div className="WebsiteSearch">
      <img src={searchIcon}/>
      <input type="text" placeholder="SEARCH" onChange={this.handleChange} onKeyDown={this.handleKeyDown} value={this.state.searchQuery}></input>
      </div>
      )
  }
}

class HeaderNotifications extends React.Component {
  render() {
    return (
      <div className="HeaderNotifications">
        <img src={notificationsIcon}/>
      </div>
      )
  }
}

function UserPreferences(props) {
    let userInfo = getUserInfo()
    const { authService } = useOktaAuth();
    return (
      <div className="UserPreferences">
        <img src={userIcon}/>
        <div className="UserPreferenceMenu">
          <ul>
            <li className="UserDetails">
              <h3>{userInfo != null ? userInfo.name : "Guest"}</h3>
              <h5>{userInfo != null ? userInfo.email : ""}</h5>            
            </li>
            <li className="MenuOption">Preferences</li>
            <li className="MenuOption" onClick={() => authService.logout('/')}>Logout</li>
          </ul>      
        </div>
      </div>
      )
}


// Main Content

class CodeWebMain extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      branch: "master"
    }
  }

  getActiveModule() {
    let moduleMap = {
      "dashboard": <Dashboard projectClick={(val) => this.props.projectClick(val) }/>,
      "project": <ContentNavigator 
                      getRepo={() => this.props.getRepo()} 
                      setRepo={(repo) => { this.props.setRepo(repo); this.setState({branch: "master"}); }} 
                      getBranch={() => this.state.branch}
                      setBranch={(branch) => this.setState({branch: branch})} 
                      getSelected={() => this.props.getSelected()}
                      setSelected={(val) => this.props.setSelected(val)}
                      />,
      "snippets": <Snippets />,
      "search": <SearchResults query={this.props.searchQuery}/>,
      "team": <NopWorkflow />,
      "import": <ImportModal closeClick={(repo) => { this.props.projectClick(repo);  
                                                     window.history.pushState({}, '', window.location.pathname.substring(1)); }} 
                             repoChange={(repo) => this.props.setRepo(repo) }
                             repoUrl={this.props.query.repo}/>,
      "code": <CodeViewer source={this.props.query.source} standalone={true}/>,
      "empty": <EmptyState />
    }
    return moduleMap[this.props.getActive()]
  }

  render() {
    const active = this.getActiveModule()
    return (
      <div className="ContentContainer">
        <MenuBar onClick={(key) => {this.props.setActive(key); window.history.pushState({}, '', key); }} 
                 active={this.props.getActive()} />
        {active}
      </div>
      )
  }
}

class MenuBar extends React.Component {

  constructor(props) {
    super(props);
    this.menuItems = {
      "dashboard": dashboardLogo,
      "project": projectLogo,
      "snippets": snipetsLogo,
      "team": teamLogo
    }
    this.onClick = props.onClick;
  }

  clickHandler(key) {
    this.onClick(key)
  }

  render() {

    const entries = []    
    for (const [key, value] of Object.entries(this.menuItems)) {
      let className = ""
      if (key === this.props.active) {
        className = "selected"
      }
      entries.push(<li key={key} className={className} 
                       onClick={() => this.clickHandler(key)}><img src={value} className="menu-icon" alt="dashboard logo" /></li>)
    }    

    return (
      <div className="MenuBar">
      <ul>
        {entries}
      </ul>
      </div>
      )
  }
}


class ContentNavigator extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      share: false
    }
  }

  fetchResults() {
    if (this.props.getRepo() == null || this.props.getRepo().length == 0) {
      fetch(getAPIHostname() + "/projects?uid=" + getUserId())
          .then(res => res.text())
          .then(res => this.props.setRepo(JSON.parse(res).Repos[0]))
          .catch(err => console.log(err))
    }
  }

  componentDidMount() {
    this.fetchResults();
  }

  getRepoName() {
    if (this.props.getRepo() != null) {
      let parts = this.props.getRepo().split("/")
      if (parts.length > 1) {
        return parts.slice(-1)[0].replaceAll(".git","")
      }
    }
    return ""
  }

  getActiveModule() {
    return {
      "branches": <CodeBranches repo={this.props.getRepo()} branch={this.props.getBranch()}  onBranchChange={(branch) => this.props.setBranch(branch)}/>,
      "scorecard": <ProjectScorecard repo={this.props.getRepo()} branch={this.props.getBranch()} 
                                    shareOn={() => this.state.share} shareOff={() => this.setState({share: false})}/>,
      "graph": <CodeGraph repo={this.props.getRepo()} branch={this.props.getBranch()} />,
      "contributor": <ContributorGraph repo={this.props.getRepo()} branch={this.props.getBranch()} />
    }[this.props.getSelected()]
  }

  render() {
    let active = this.getActiveModule()
    return (
      <div className="ContentNavigator">
          <NavigatorHeader repo={this.props.getRepo()} 
                         repoName={this.getRepoName()} 
                         branchName={this.props.getBranch()} 
                         onRepoChange={(repoName) => this.props.setRepo(repoName)}
                         onClick={(val) => this.props.setSelected(val) }
                         getSelected={() => this.props.getSelected()}
                         setSelected={(val) => this.props.setSelected(val)}
                         onShareClick={() => this.setState({share: true})}
                         />
          {active}
      </div>
      )
  }

}


class NavigatorHeader extends React.Component {

  constructor(props) {
    super(props);
    this.onClick = props.onClick;
    this.onRepoChange = props.onRepoChange
  }

  render() {
    return (
      <div className="NavigatorHeader">
        <BranchSelector repo={this.props.repo} 
                        repoName={this.props.repoName} 
                        branchName={this.props.branchName} 
                        onRepoChange={(repoName) => this.onRepoChange(repoName)}/>
        <WorkflowSelector onClick={this.onClick}
                          getSelected={() => this.props.getSelected()}
                          setSelected={(val) => this.props.setSelected(val)}
                          />
        <SharingLink getSelected={() => this.props.getSelected()} onShareClick={() => this.props.onShareClick()} />
      </div>
      )
  }

}

function SharingLink(props) {
  return <div className="SharingLink">
            { (props.getSelected() === "scorecard") &&
                <div className="SharingButton" onClick={() => props.onShareClick()}>
                  <img src={shareIcon}/>
                  <div className="ShareText">Share this scorecard</div>
                </div>
              }
            </div>
}

class BranchSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      repos: []
    }
  }

  fetchResults() {
    fetch(getAPIHostname() + "/projects")
        .then(res => res.text())
        .then(res => this.setState({"repos": JSON.parse(res).Repos}))
        .catch(err => console.log(err))
  }

  componentDidMount() {
    this.fetchResults()
  }

  getRepoName(entry) {
    entry = entry.split("/")
    if (entry.length > 0) {
      return entry.slice(-1)[0]
    } else {
      return ""
    }
  }

  render() {
    let repos = []
    if (this.state.repos) {
      let repoList = this.state.repos.sort((a,b) => a.split("/").slice(-1)[0].localeCompare(b.split("/").slice(-1)[0]))
      for (let i=0; i<repoList.length; i++) {
        repos.push(<li onClick={() => { this.props.onRepoChange(repoList[i]); this.setState({opened: false}) } }
                       key={"repo-select-" + repoList[i]}>
                        <strong>{this.getRepoName(repoList[i])}</strong><h5>{repoList[i].replace("https://","")}</h5></li>)
      }
    }
    return (
      <div className="BranchSelector" 
           onMouseEnter={() => this.setState({opened: true})}
           onMouseLeave={() => this.setState({opened: false})}
           >
        <div className="Container">
          <div className="dot"></div>
          <div className="BranchDropdown">
            <span className="strong">{this.props.repoName}</span>
            <img src={downIconWhite} />
          </div>        
        </div>
        <div className="BranchSelectDropdown">
          <div className="BranchSelectContent" 
               style={this.state.opened ? {display: "block"} : {}}>
          <ul>
            {repos}
          </ul>
          </div>
        </div>
        <div className="BranchLabel">
          <img src={forkIcon} />
          <h4><span className="strong">{this.props.branchName}</span> branch</h4>
        </div>
      </div>
      )
  }

}


class WorkflowSelector extends React.Component {

  constructor(props) {
    super(props);
    this.menuItems = {
      "branches": "Code Branches",
      "scorecard": "Project Scorecard",
      "graph": "Code Graph",
      "contributor": "Contributor Graph"
    }
    this.onClick = props.onClick
  }

  clickHandler(key) {
    this.setState({
      selected: key
    })
    this.onClick(key)
  }

  render() {

    const entries = []    
    for (const [key, value] of Object.entries(this.menuItems)) {
      let className = ""
      if (key === this.props.getSelected()) {
        className = "selected"
      }
      entries.push(<li key={key} className={className} onClick={() => this.clickHandler(key)}>{value}</li>)
    }    

    return (
      <div className="WorkflowSelector">
      <ul>
        {entries}
      </ul>
      </div>
      )
  }

}


class NopWorkflow extends React.Component {
  render() {
    return (
      <div></div>
      )
  }
}

class MasterFrame extends React.Component {
  constructor(props) {
    super(props);
    this.query = queryString.parse(this.props.location.search)
    this.state = {
      "active": this.getActiveComponent(),
      "selected": (this.query.selected != null) ? this.query.selected : "scorecard",
      "searchQuery": "",
      "repo": (this.query.repo != null) ? this.query.repo : "" 
    }
  }

  getActiveComponent() {  // url parameter overrides the path
    return this.query.active ? this.query.active : window.location.pathname.substring(1)
  }

  render() {
    return (
      <div className="MasterFrame">
        <CodeWebHeader onImportClick={() => this.setState({active: "import"})} 
                       onSearchQuery={(query) => this.setState({active: "search", searchQuery: query})}/>
        <CodeWebMain setActive={(key) => this.setState({active: key})} 
                     getActive={() => this.state.active} 
                     projectClick={(val) => this.setState({active: "project", "repo": val}) }
                     searchQuery={this.state.searchQuery}
                     getRepo={() => this.state.repo}
                     setRepo={(val) => this.setState({"repo": val})}
                     getSelected={() => this.state.selected}
                     setSelected={(val) => this.setState({selected: val})}
                     query={this.query}
                     />
      </div>
    );
  }

}

export default MasterFrame;
