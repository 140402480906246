import React from 'react';
import './EmptyState.css';

import ImportProject from './ImportProject'
// import downIcon from '../img/arrowdown.svg';
import progressIcon from '../img/import-progress.svg';

class EmptyState extends React.Component {

  render() {

    if (this.props.emptyAccount) {
      return (<div className="EmptyAccountContainer">
          <div className="EmptyAccount">
            <img src={progressIcon} className="progresslogo" />
            <h5>Looks like you haven't imported any projects yet</h5>
            
          </div>
        </div>)
    } else {
      let dots = []
      for (let i=0; i<9; i++) {
        dots.push(<div className={"dot dot-" + i} key={"dot-" +i}
                       style={{animation: "leaves " + (1 + i/5) + "s ease-in-out infinite alternate"}} ></div>)
      }
      return (
        <div className="EmptyAccountContainer">
          <div className="Loader">
            <div className="ContentLoader">
              {dots}
            </div>
            {this.props.loadingTitle && <h5>loading...</h5>}
          </div>
        </div>
        )
    }
  }

}


export default EmptyState;