const CLIENT_ID = process.env.CLIENT_ID || '0oa63toxnncM6gNgF357';
const ISSUER = process.env.ISSUER || 'https://auth.codeweb.dev/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: 'https://alpha.codeweb.dev/implicit/callback',
    // redirectUri: 'http://localhost:3000/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    // messagesUrl: 'https://alpha.codeweb.dev/api/messages',
    messagesUrl: 'http://localhost:3000/api/messages',
  },
};
