import React from 'react';
import {reactLocalStorage} from 'reactjs-localstorage';
import md5 from 'md5';
import { hashRandom, hashString, hashObject, hashArray } from 'react-hash-string'
import './Utils.css';

export const getUserId = () => {
	if (reactLocalStorage.getObject("userinfo") != null 
		&& reactLocalStorage.getObject("userinfo") !== undefined
		&& (reactLocalStorage.getObject("userinfo")).email != null) {
		return md5(reactLocalStorage.getObject("userinfo").email)	
	} else {
		return "guest"
	}
}

export const getUserInfo = () => {
	if (reactLocalStorage.getObject("userinfo") != null && reactLocalStorage.getObject("userinfo") !== undefined) {
		return reactLocalStorage.getObject("userinfo")	
	} else {
		return {"foo": "bar"}
	}
}

export const getCodewebHostname = () => {
	// return "http://localhost:3000"
	return "https://alpha.codeweb.dev"
}	

export const getAPIHostname = () => {
	// return "http://preprint.io:10000"
	// return "http://localhost:10000"
	return "https://api.codeweb.dev"
}

export const getSearchHostname = () => {
	// return "http://preprint.io:9200"
	// return "http://localhost:9200"
	// return "https://search.codeweb.dev"
	return "https://proxy.codeweb.dev/api/"
}

export const getProxyHostname = () => {
	// return "http://preprint.io:9000"
	// return "http://localhost:9000"
	return "https://proxy.codeweb.dev"
}

export const randomColor = () => {
	let palette = ["#76aac5", "#e7cfa6", "#d5d6c5", "#c1452d", "#80b084"]
	return palette[Math.floor(palette.length*Math.random())]
}

export const getFileColor = (fileName) => {
	let palette = ["#e7cfa6", "#d5d6c5", "#c1452d", "#80b084", "#76aac5"]
	let extension = fileName.split("\.").slice(-1)[0]
	// return palette[hashString(extension)%palette.length]
	// return palette[Math.abs(hashString(extension))%palette.length]
	return palette[Math.abs(hashString(fileName))%palette.length]
}


export const getRandomWord = () => {
	let text = "in the practice of the use of language one party calls out the words, the other acts on them. In instruction in the language the following process will occur: the learner names the objects; that is he utters the word when the teacher points to the stone And there will be this still simpler exercise: the pupil repeats the words after the teacher both of these being processes resembling language We can also think of the whole process of using words in as one of those games by means of which children learn their native language. I will call these games language-games and will sometimes speak of a primitive language as a language-game And the processes of naming the stones and of repeating words after someone might also be called language-games. Think of much of the use of words in games like ring-a-ring-a-roses I shall also call the whole, consisting of language and the actions into which it is woven, the language-game".split(" ")
	return text[Math.floor(Math.random()*text.length)]
}