import React from 'react';

class ActivityTimeline extends React.Component {

	constructor(props) {
    	super(props);
    	this.labels=["jan","feb", "mar", "apr", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]
    	this.days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"]
	}

	render() {
		let data = this.props.data;
		let entries = []
		let rowOffset = 20
		let columnOffset = 0
		let xoffset = 25

		for (let i=1; i<data.length; i++) {
			entries.push(<circle key={"circle-"+i} cx={20 + xoffset + columnOffset} cy={rowOffset} r={Math.min(8,0.5 + data[i]/5)} fill="#fff" opacity={Math.min(0.4+ data[i]/5,1)} />)
			if (i%7 === 0) {
				columnOffset+= 20
				rowOffset = 0
			}
			rowOffset += 20

		}

		let labelOffset = 162

		// for (let i=1; i<=24; i++) {
		// 	if ((1+i)%4 === 0) {
		// 		entries.push(<text key={"dot-"+i} x={xoffset + i*20} y={labelOffset} fill="rgba(255,255,255,0.3)" 
		// 						   fontSize="10px" textalign="center" alignmentBaseline="center" textAnchor="middle" 
		// 						   fontFamily="Roboto" fontWeight="300">{(this.labels[Math.floor(i/4)]).toUpperCase()}</text>)
		// 	}
		// }

		// todo: compute actual month backtracking
		let numMonths = Math.floor(data.length/30) + 1
		for (let i=0; i<numMonths; i++) {
				entries.push(<text key={"dot-"+i} x={20 + xoffset + i*85} y={labelOffset} fill="rgba(255,255,255,0.3)" 
								   fontSize="10px" textalign="center" alignmentBaseline="center" textAnchor="middle" 
								   fontFamily="Roboto" fontWeight="300">{this.labels[4+i].toUpperCase()}</text>)
		}


		for (let i=0; i<this.days.length; i++) {
				entries.push(<text key={"day-"+i} x={xoffset+5} y={22+i*20} fill="rgba(255,255,255,0.25)" 
								   fontSize="7px" textalign="end" alignmentBaseline="end" textAnchor="end" 
								   fontFamily="Roboto" fontWeight="300">{this.days[i].toUpperCase()}</text>)
			}
    	return (<div className="ActivityTimeline">
    			<svg width="520" height="170">
    			{entries}
    			</svg>
    			</div>
	)}

}


export default ActivityTimeline;
