import React from 'react';
import './ProjectScorecard.css';

import checkmarkIcon from '../img/check-mark.svg';
import checkmarkDisabledIcon from '../img/check-mark-disabled.svg';
import githubIcon from '../img/github.svg';
import moveIcon from '../img/move.svg';
import removeIcon from '../img/remove.svg';
import trendUpIcon from '../img/trend-up.svg';
import trendDownIcon from '../img/trend-down.svg';

import ActivityTimeline from './ActivityTimeline'
import SharingModal from './SharingModal'
import EmptyState from './EmptyState'

import { getUserId, ContentLoading, getAPIHostname, getCodewebHostname } from '../util/Utils'

function EditControl(props) {
    return(
		<div className="EditModeControl">
			<div className="ControlButton"><img src={moveIcon}/>Move</div>
			<div className="ControlButton" onClick={() => props.onRemove()}><img src={removeIcon}/>Remove</div>
		</div>
      )
}

class ScorecardEditController extends React.Component {
	constructor(props) {
		super(props);
		this.onClick = props.onClick;
		this.state = {
      		enabled: false
    	}
	}

	toggleState() {
		this.setState({
	      enabled: !this.state.enabled
    	})
    	this.onClick(this.state.enabled)
	}

	render() {
		let style = {"justifyContent": this.state.enabled ? "flex-end" : "flex-start",
					 "backgroundColor": this.state.enabled ? "#ef5b89" : "#8D9096"}
		return (
			<div className="ScorecardControl">
		      	<div className="ScorecardEdit">
		      		<h4>Edit Mode</h4>
		      		<div className="EditToggle" onClick={() => this.toggleState()} style={style}>
		      			<div className="ToggleButton"></div>		      			
		      		</div>
					<h3>{this.state.enabled ? "ON" : "OFF"}</h3>
		      	</div>
		      	<p>{this.state.enabled ? "Your project scorecard is currenty in edit mode. You can change the scorecard layout by dragging and/or removing appropriate sections." : ""}</p>
	      </div>
		)
	}

}

class ProjectScorecard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      data: this.getInitStateData(),
      cardOrder: [0,1,2,3,4,5,6,7,8]
    }
  }

  getInitStateData() {
  	return {
    	"ProjectName": "",
	    "RepoUrl": "",
	    "CloneUrl": "",
	    "ProjectDescription": "",
	    "CommitCount": "",
	    "githubTop100Projects": false,
	    "ContributorCount": "",
	    "Releases": "",
	    "Branches": ""
      }
  }

  getData(res) {
  	try {
		let data = JSON.parse(res)
		return data
	} catch (err) {
		return {}
	}
  }

  fetchResults() {
  	if (this.props.repo) {
		fetch(getAPIHostname() + "/scorecard?repo=" + this.props.repo + "&branch=" + this.props.branch  + "&usecache")
	    	.then(res => res.text())
	    	.then(res => this.setState({"data": this.getData(res)}))
	    	.catch(err => this.setState({"data": null}))
	}
  }

  commitHistory(entry, maxEntries) {
  	let result = []
  	if (entry.LastCommits) {
  		for (let i=0; i<Math.min(entry.LastCommits.length, maxEntries); i++) {
  			if (entry.LastCommits[i]) {
	  			result.push(
	  				<div className="CommitHistoryEntry" key={"last-commit-" + i}>
		  				<h5>{entry.LastCommits[i].Author.When.replace("T", " / ")}</h5>
		  				<h4>{entry.LastCommits[i].Message}</h4>
		  				<h5>{entry.LastCommits[i].Author.Name}</h5>
	  				</div>
	  				)
	  		}
  		}
  	}
  	return result
  }

  getAverage(data) {
  	if (data != null) {
  		let average = (array) => array.reduce((a, b) => a + b) / array.length;
  		return average(data)
  	} else {
  		return 0;
  	}
  }

  isLoading() {
  	return this.props.repo != null && this.props.repo.length > 0 && this.props.repo != this.state.data.RepoUrl;
  }

  removeCard(idx) {
  	this.setState({cardOrder: this.state.cardOrder.filter(item => item !== idx) })
  }

  swapCards(id1, id2) {
  	let i1 = this.state.cardOrder.indexOf(parseInt(id1))
  	let i2 = this.state.cardOrder.indexOf(parseInt(id2))
  	let result = this.state.cardOrder
  	let tmp = result[i1]
  	result[i1] = result[i2]
  	result[i2] = tmp
  	this.setState({cardOrder: result})
  }

  projectCard(entry, style) {
  	return <div className="ProjectTitleCard ProjectCard ProjectLoading" draggable={this.state.editMode} 
  				key={"card-" + entry.ProjectName}
  				style={style} id={"project-" + entry.ProjectName}>
		        	<div className="projectDescription">
		        		<h2>{entry.ProjectName}</h2>
		        		<h4>{entry.ProjectDescription}</h4>
		        	</div>
		        	<div className="ProjectLink">
			    		<a className="github-link" href={entry.RepoUrl}>
			        		<img src={githubIcon}/>
							<h4>/{entry.RepoUrl.split("/").slice(-1)[0]}</h4>
						</a>
			        </div>
		        </div>
  }

  commitActivityCard(entry, style, idx) {
	return <div className="CommitActivityCard ProjectCard"  draggable={this.state.editMode} style={style} id={"commit-card-" + idx} 
				onDragStart={(ev) => { ev.dataTransfer.setData("id", idx) } } 
				onDragOver={(ev) => ev.preventDefault()} 
				onDrop={(ev) => this.swapCards(idx, ev.dataTransfer.getData("id")) } >

			<div className="mini-title">Commit Activity</div>
			<div className="ActivityTimelineHolder">
				<ActivityTimeline data={entry.CommitActivity ? entry.CommitActivity : []}/>
				<div className="ActivityStatistics">
					<h5>daily average</h5>
					<h3><strong>{this.getAverage(entry.CommitActivity).toFixed(2)}</strong> commits/day</h3>
				</div>
			</div>
			<div className="ActivityCardContext">
				<h5>Last Commit</h5>
				{this.commitHistory(entry, 1)}
			</div>
			{this.state.editMode && <EditControl onRemove={() => this.removeCard(idx)} />}
        </div>
  }

  projectCommitsCard(entry, style, idx) {
	return  <div className="ProjectCommitsCard ProjectCard" draggable={this.state.editMode} style={style} id={"card-" + idx} 
				onDragStart={(ev) => { ev.dataTransfer.setData("id", idx) } } 
				onDragOver={(ev) => ev.preventDefault()} 
				onDrop={(ev) => this.swapCards(idx, ev.dataTransfer.getData("id")) } >
        	<div>
	        	<div className="counterLarge">{entry.CommitCount.toLocaleString()}</div>
	        	<h3>commits</h3>
	        	<div className="topProjects">
	        		<img src={checkmarkDisabledIcon}/>
	        		<div>Github Top 100 Projects</div>
	        	</div>
	        </div>
	        {this.state.editMode && <EditControl onRemove={() => this.removeCard(idx)} />}
        </div>

  }

  lastActivityCard(entry, style, idx) {
	return <div className="LastActivityCard ProjectCard" draggable={this.state.editMode} style={style} id={"card-" + idx} 
				onDragStart={(ev) => { ev.dataTransfer.setData("id", idx) } } 
				onDragOver={(ev) => ev.preventDefault()} 
				onDrop={(ev) => this.swapCards(idx, ev.dataTransfer.getData("id")) } >
	        	<div>
		        	<h5>Last 90 Days</h5>
		        	<div><span className="heavy">{entry.ActiveContributors}</span> active contributors</div>
		        	<div><span className="heavy">{entry.Issues}</span> new tickets</div>
		        </div>
		        <img src={trendDownIcon}/>
		        {this.state.editMode && <EditControl onRemove={() => this.removeCard(idx)} />}
	        </div>
  }

  activeReleasesCard(entry, style, idx) {


  	let entries = []
  	if (entry.LastReleases) {
  		if (entry.LastReleases[0]) {
	  		for (let i=0; i<Math.min(entry.LastReleases.length, 6); i++) {
	  			entries.push(<h3>{entry.LastReleases[i]}</h3>)
	  		}   		
	  	} else {
	  		entries.push(<h4>No release names available</h4>)	
	  	}
  	}

	return <div className="ActiveReleasesCard ProjectCard" draggable={this.state.editMode} style={style} id={"card-" + idx} 
				onDragStart={(ev) => { ev.dataTransfer.setData("id", idx) } } 
				onDragOver={(ev) => ev.preventDefault()} 
				onDrop={(ev) => this.swapCards(idx, ev.dataTransfer.getData("id")) } >
	        	<div>
	        		<h5>Latest Releases</h5>
	        		<div className="ReleasesList">
	        			{entries}
	        		</div>
	        	</div>
	        	{this.state.editMode && <EditControl onRemove={() => this.removeCard(idx)} />}
	        </div>
  }

  contributorsCard(entry, style, idx) {
	return <div className="ContributorsCard ProjectCard" draggable={this.state.editMode} style={style} id={"card-" + idx} 
				onDragStart={(ev) => { ev.dataTransfer.setData("id", idx) } } 
				onDragOver={(ev) => ev.preventDefault()} 
				onDrop={(ev) => this.swapCards(idx, ev.dataTransfer.getData("id")) } >
	        	<div>
		        	<div className="counterLarge">{entry.ContributorCount.toLocaleString()}</div>
		        	<h3>contributors</h3>
		        	<div className="topProjects">
		        		<img src={checkmarkDisabledIcon}/>
		        		<div>Github Top 100 Projects</div>
		        	</div>
		        </div>
		        {this.state.editMode && <EditControl onRemove={() => this.removeCard(idx)} />}
	        </div>  	
  }

  releasesCard(entry, style, idx) {
	return <div className="ReleasesCard ProjectCard" draggable={this.state.editMode} style={style} id={"card-" + idx} 
				onDragStart={(ev) => { ev.dataTransfer.setData("id", idx) } } 
				onDragOver={(ev) => ev.preventDefault()} 
				onDrop={(ev) => this.swapCards(idx, ev.dataTransfer.getData("id")) } >
	        	<div>
		        	<div className="counterLarge">{entry.Releases.toLocaleString()}</div>
		        	<h3>releases</h3>
		        </div>
		        {this.state.editMode && <EditControl onRemove={() => this.removeCard(idx)} />}
	        </div>
  }

  branchesCard(entry, style, idx) {
	return <div className="BranchesCard ProjectCard" draggable={this.state.editMode} style={style} id={"card-" + idx} 
				onDragStart={(ev) => { ev.dataTransfer.setData("id", idx) } } 
				onDragOver={(ev) => ev.preventDefault()} 
				onDrop={(ev) => this.swapCards(idx, ev.dataTransfer.getData("id")) } >
	        	<div>
		        	<div className="counterLarge">{entry.BranchCount}</div>
		        	<h3>branches</h3>
		        </div>
		        {this.state.editMode && <EditControl onRemove={() => this.removeCard(idx)} />}
	        </div>
  }

  commitHistoryCard(entry, style, idx) {
	return <div className="CommitHistoryCard ProjectCard" draggable={this.state.editMode} style={style} id={"card-" + idx} 
				onDragStart={(ev) => { ev.dataTransfer.setData("id", idx) } } 
				onDragOver={(ev) => ev.preventDefault()} 
				onDrop={(ev) => this.swapCards(idx, ev.dataTransfer.getData("id")) } >
	        	<div className="mini-title">Commit History</div>
	        	<div className="CommitHistoryContainer">
		        	{this.commitHistory(entry, 6)}
		        </div>
	        	{this.state.editMode && <EditControl onRemove={() => this.removeCard(idx)} />}
	        </div>
  }

  getCard(idx, entry, style) {
  	switch(idx) {
  		case 0:
  			return this.projectCard(entry, style, idx);
  		case 1:
  			return this.commitActivityCard(entry, style, idx);
  		case 2:
  			return this.projectCommitsCard(entry, style, idx);
  		case 3:
  			return this.lastActivityCard(entry, style, idx);
  		case 4:
  			return this.activeReleasesCard(entry, style, idx);
  		case 5:
  			return this.contributorsCard(entry, style, idx);
  		case 6:
  			return this.releasesCard(entry, style, idx);
  		case 7:
  			return this.branchesCard(entry, style, idx);
  		case 8:
  			return this.commitHistoryCard(entry, style, idx);
  		default:
  			return this.projectCard(entry, style, idx);
  	}
  }


  render() {
  	let entry = this.state.data
  	if(this.props.repo != null && this.props.repo.length > 0 && this.props.repo != this.state.data.RepoUrl) {
  		entry = this.getInitStateData();
  		this.fetchResults();
  	}
  	let style = this.isLoading() ? {"animationName": "loading"} : {}

  	let cards = []
  	for (let i=0; i<this.state.cardOrder.length; i++) {
  		cards.push(this.getCard(this.state.cardOrder[i], entry, style))
  	}

  	if (this.props.repo) {
	    return (
		  <div className="ScorecardContainer">    	
		      <div className="ProjectScorecard">
		      	{cards}
		      </div>

		      <ScorecardEditController onClick={(val) => this.setState({editMode: !val})}/>

		      {this.props.shareOn() && 
		      	<SharingModal onCloseClick={() => this.props.shareOff()} 
		      				  permalink={getCodewebHostname() + "/dashboard?active=project&selected=scorecard&repo=" + this.props.repo }/>
		      }

	      </div>
	      )
	  } else {
	  	return <EmptyState emptyAccount={true} />
	  }
  }

}

export default ProjectScorecard;
