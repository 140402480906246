import React from 'react';
import './Snippets.css';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { hybrid } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import {reactLocalStorage} from 'reactjs-localstorage';
import EmptyState from './EmptyState';

class Snippets extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: reactLocalStorage.getObject('snippets')
    }
  }

  render() {
    let entries = []
    let data = this.state.data
    if (data.queue) {
      for (let i=0; i<Math.min(data.queue.length,20); i++) {
        let entry = decodeURIComponent(data.queue[i].payload).replaceAll("+", " ")
        entry = entry.substring(0,2048)
        // entries.push(<p language="go" style={hybrid}>{decodeURIComponent(data.queue[i]).replaceAll("+", " ")}</p>)
        entries.push(<div className="SnippetPreview">
          <div className="SnippetHeader">
            <h4>{data.queue[i].title}</h4>
            <h5>{data.queue[i].repo}</h5>
          </div>
          <SyntaxHighlighter language="go" style={hybrid}>{entry}</SyntaxHighlighter>
          </div>)
      }
    }
    return (<div className="SnippetsWrapper">
              <h3>Saved Snippets</h3>
      <div className="SnippetsContainer">
          <div className="Snippets">
            {entries}
          </div>
      </div>
      </div>)
  }

}




export default Snippets;