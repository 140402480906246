import React from 'react';
import './CodeGraph.css';

import { getUserId, randomColor, getFileColor, getAPIHostname } from '../util/Utils'
import backIcon from '../img/trend-down.svg';
import EmptyState from './EmptyState';
import CodeViewer from './CodeViewer'

class FolderContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			stroke: "rgba(254,255,254,0)"
		}
		this.strokeWidth = 5
		this.palette = ["#76aac5", "#e7cfa6", "#d5d6c5", "#c1452d", "#80b084"]
	}

	toRadians (angle) {
	  return angle * (Math.PI / 180);
	}

	toDegrees (angle) {
	  return angle * (180 / Math.PI);
	}

	getNodes(count) {
		let result = []
		let angleDelta = 20

		// result.push(<circle cx={this.props.r} cy={this.props.r} r={30} strokeWidth="1px" stroke="rgba(255,255,255,0.1)" fill="none" />)

		let dist = 0
		for (let i=0; i<Math.min(count,70); i++) {
			let phi = (i*angleDelta)%360
			dist = this.props.r*0.4 + Math.floor(i/18)*15
			let cx = 5+this.props.r + dist*Math.cos(this.toRadians(phi))
			let cy = 5+this.props.r + dist*Math.sin(this.toRadians(phi))
			// console.log(this.props.data[i].Name)
			// let fill = getFileColor(this.props.data[i].Name)
			let fill = this.palette[i%this.palette.length]
			result.push(<circle cx={cx} cy={cy} r={1 + Math.min(5, this.props.data[i].Size/1024) } 
						fill={fill} key={"circle-" + i} />)
		}
	
		if (count < 30) {			
			result.push(<circle cx={5+this.props.r} cy={5+this.props.r} r={dist+18} strokeWidth="1px" 
					            stroke="rgba(255,255,255,0.1)" fill="none" key={"circle-dot-" + count}/>)
		}
		return result
	}

	render() {
		let nodes = this.getNodes(this.props.nodeCount)
		return (
			<svg height={2*this.props.r+45} width={2*this.props.r+10} onClick={() => this.props.onClick(this.props.label)} >
			<circle cx={this.props.cx} cy={this.props.cy} r={this.props.r} 
					stroke={this.state.stroke} strokeWidth={this.strokeWidth} fill="none" />
			{nodes}
			<text x={this.props.r } y={this.props.r*2 + (this.props.nodeCount < 30 ? 0 : 35)}
				  textAnchor="middle" textalign="center" alignmentBaseline="center" fill="#d6c19c"
				  >{this.props.label}</text>
			</svg>

		)
	}
}

function FileContainer(props) {
	return (
		<svg height={2*props.r+40} width={2*props.r} onClick={props.onClick}>
			<circle cx={100} cy={100} r={Math.min(30, 5 + props.size/1024)} 
					stroke={getFileColor(props.name)} strokeWidth={1} fill={"#282c30"} />
			<text x={props.r } y={props.r*2}
				  textAnchor="middle" textalign="center" alignmentBaseline="center" fill="#d6c19c"
				  >{props.name.split("/").slice(-1)[0]}</text>
			<text x={props.r } y={props.r*2 + 20}
				  textAnchor="middle" textalign="center" alignmentBaseline="center" fill="#fff"
				  >{Math.ceil(props.size/1024) + "kB"}</text>
		</svg>
		)

}

class GraphVisualization extends React.Component {

	constructor(props) {
		super(props);
	}

	getTitle(repo) {
		return repo ? repo.split("/").slice(-1)[0] : ""
	}

	render() { 
		let entries = []
		let nodes = Object.keys(this.props.data)

		// first the folders
		for (let i=0; i<nodes.length; i++) {
			entries.push(<FolderContainer r={100} label={nodes[i]} nodeCount={this.props.data[nodes[i]].length} 
										  key={"folder-container-" + i}
										  data={this.props.data[nodes[i]]} onClick={(file) => this.props.onClick(file)}/>)
		}
		
		// then, if space permits - files
		if (nodes.length < 10) {
			// let files = this.props.files.filter(entry => (entry.Name.indexOf("/") == -1))
			let files = this.props.files
			for (let i=0; i<files.length; i++) {
				entries.push(<FileContainer r={100} name={files[i].Name} size={files[i].Size} r={100}
											onClick={() => this.props.fileClick(files[i].Name)}/>)
				if (i > 200) {	// todo: add pagination
					break;
				}
			}
		}

		let style = this.props.isLoading() ? {"animationName": "loading"} : {}
		return (
			<div className="GraphVisualizationWrapper">
				<div className="GraphHeader">
					<div className="ActivePath" style={style} onClick={() => this.props.backClick()}>
							<span className="RepoName">{this.getTitle(this.props.repo)}</span>
							<span>{"/" + (this.props.graphView ? this.props.root : this.props.source)}</span>
							<div className="LastCommitMessage">{"Last commit 7 days ago"}</div>
					</div>
					{ this.props.graphView ?
						<div className="BackNav" onClick={() => this.props.backClick()} 
							 style={{"display": this.props.root.length == 0 ? "none" : "flex"}}>
							<img src={backIcon} style={{"transform": "rotate(-225deg)"}}/>
							<h5>One level back</h5>
						</div>
					:
						<div className="BackNav" onClick={() => this.props.viewerBackClick()} 
							 style={{"display": "flex"}}>
							<img src={backIcon} style={{"transform": "rotate(-225deg)"}}/>
							<h5>Back to graph view</h5>
						</div>
					}
				</div>
				{ this.props.graphView ? 
					<div className="CodeGraph">
						{this.props.isLoading() &&  <EmptyState loadingTitle={true} /> }
						{entries}
					</div>
				:
					<CodeViewer source={this.props.source}/>
				}
			</div>
			)
	}

}

class CodeGraph extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
      		root: "",
      		data: this.getInitStateData(),
      		fileSort: "size",
			graphView: true,
			source: ""
    	}
    	this.repo = props.repo;
	}

	getInitStateData() {
		return {
      			"RepoUrl": "",
      			"Files": []
      		}
	}

	fetchResults() {
		if (this.props.repo) {
			fetch(getAPIHostname() + "/tree?repo=" + this.props.repo + "&branch=" + this.props.branch + "&usecache")
				.then(res => res.text())
				.then(res => this.setState({"data": JSON.parse(res)}))
				.catch(err => console.log(err))
		}
    }

	isLoading() {
		return this.props.repo && (this.props.repo.length > 0) 
				&& (this.props.repo !== this.state.data.RepoUrl) && (this.state.data.RepoUrl.length == 0);
	}

	aggregate(root) {
		let results = {}
		let files = this.state.data.Files
		if (files.length > 0) {
			for (let i=0; i<files.length; i++) {
				if (files[i].Name.includes("/") && files[i].Name[0] !== "." && files[i].Name.startsWith(root)) {
					let path = files[i].Name.slice(root.length)
					if (path.includes("/")) {
						var dirName = path.split("/")[0]
						if (!results[dirName]) {
							results[dirName] = []
						}
						results[dirName].push(files[i])
					}
				}
			}
		}
		return results
	}

	shortenFileName(str) {
		if (str.length < 35) {
			return str
		} else {
			let parts = str.split("/")
			let end = parts.slice(-1)[0]
			let shortened = parts[0] + "/../" + end
			if (shortened.length < 35) {
				return shortened
			} else if (end.length < 35) {
				return end
			} else {
				return end.substr(0,30) + ".."
			}
		}
	}

	// for active folder sidebar rendering
	getFileList(files) {
		let entries = []
		if (files.length > 0) {
			for (let i=0; i<files.length; i++) {
				if (files[i].Name.startsWith(this.state.root)) {
					entries.push(<div className="FileListEntry" key={"file-" + i}
									  onClick={() => this.setState({source: files[i].Name, graphView: false})}>
									<div key={"file-" + i + "-name"} className="FileNameContainer">
										<div className="dot" style={{"backgroundColor": getFileColor(files[i].Name)}}></div>
										<div className="FileName" >
											 {this.shortenFileName(files[i].Name.slice(this.state.root.length))}
										</div>
									</div>
									<div className="file-size" key={"file-" + i + "-size"}>{Math.ceil(files[i].Size/1024) + " kB"}</div>
								</div>)	
				}			
				if (entries.length === 200) {	// render up to 200 files without pagination
					break;
				}
			}
		}
		// console.log(this.state.fileSort)
		return (<div className="FileList">
			<div className="FileListHeader noselect">
			<h5 onClick={() => this.setState({fileSort: "name"})} >File Name</h5>
			<h5 onClick={() => this.setState({fileSort: "size"})} >File Size</h5>
			</div>
			{entries}
			</div>
			)
	}

	render() {

		let path = this.state.root.split("/")
		let back = path.slice(0, path.length-2).join("/")

		if(this.props.repo !== this.state.data.RepoUrl) {
  			this.fetchResults();
  		}

  		let files = this.state.data.Files.filter(file => file.Name.startsWith(this.state.root))
		if (this.state.fileSort === "size") {
			files = files.sort((a,b) => { return b.Size - a.Size})
		} else {
			files = files.sort((a, b) => a.Name.localeCompare(b.Name))
		}

  		if (!this.isLoading()) {
			return (<div className="CodeGraphContainer">
				<GraphVisualization data={this.aggregate(this.state.root)} root={this.state.root} isLoading={() => this.isLoading()}
									onClick={(file) => this.setState({root: this.state.root + file + "/"})}
									backClick={() => this.setState({root: back}) }
									fileClick={(fileName) => this.setState({source: fileName, graphView: false})}
									viewerBackClick={(fileName) => this.setState({graphView: true})}
									repo={this.props.repo} files={files} graphView={this.state.graphView} source={this.state.source}/>
				{this.getFileList(files)}
				</div>)
		} else {
			return <EmptyState loadingTitle={true} />		
		}
	}

}




export default CodeGraph;