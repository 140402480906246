import React from 'react';
import './Dashboard.css';

import { connect } from "react-redux";

import EmptyState from './EmptyState'

import { getUserId, getAPIHostname } from '../util/Utils'

const mapStateToProps = state => {
  return { articles: state.articles };
};

const ConnectedList = ({ articles }) => (
  <ul>
    {articles.map(el => (
      <li key={el.id}>{el.title}</li>
    ))}
  </ul>
);

const List = connect(mapStateToProps)(ConnectedList);


function GraphBar(props) {
    return(
      <div>
        <div className="BarBase">
          <div className="BarActive" style={{"height": props.height + "px"}}></div>
        </div>
        <div className="Month">{props.title}</div>
      </div>
      )
}

function HorizontalBar(props) {
    let height = props.height ? props.height : "12px"
    return(
      <div>
        <div className="HorizontalBarBase" style={{"height": height}}>
          <div className="HorizontalBarActive" style={{"width": props.val + "px", "backgroundColor": props.color, "height": height}}></div>
        </div>
        <div className="Month">{props.title}</div>
      </div>
      )
}


function PieChart(props) {
    return(
      <svg height="220" width="200">
          <circle cx="100" cy="100" r="75" stroke="#3e4145" strokeWidth="15" fill="none" />
          <circle cx="100" cy="100" r="75" stroke="#FEBB2B" strokeDasharray="0 353 500" strokeWidth="15" fill="none" />
          <circle cx="100" cy="100" r="75" stroke="#78AAC5" strokeDasharray={"220 300"} strokeWidth="15" fill="none" />
          <text x="100" y="100" fill="#fff" fontSize="21px" textalign="center" alignmentBaseline="center" textAnchor="middle" fontFamily="Roboto" fontWeight="500">{props.val}</text>
          <text x="100" y="120" fill="#686b6c" fontSize="12px" textalign="center" alignmentBaseline="center" textAnchor="middle" fontFamily="Roboto">{props.label}</text>
          
          <circle cx="5" cy="211" r="4" fill="#febb2b" />
          <text x="15" y="215" fill="#686b6c" fontSize="12px" textalign="start" alignmentBaseline="center" textAnchor="start" fontFamily="Roboto">active</text>

          <circle cx="65" cy="211" r="4" fill="#77a9c4" />
          <text x="75" y="215" fill="#686b6c" fontSize="12px" textalign="start" alignmentBaseline="center" textAnchor="start" fontFamily="Roboto">maintenance</text>

          <circle cx="160" cy="211" r="4" fill="#4C5156" />
          <text x="170" y="215" fill="#686b6c" fontSize="12px" textalign="start" alignmentBaseline="center" textAnchor="start" fontFamily="Roboto">other</text>

      </svg>
      )
}

class ActivityGraph extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let entries = []
    for (let i=0; i<12; i++) {
      entries.push(<GraphBar height={Math.random()*100} title={i} key={"graph-bar-" + i}/>)
    }
    return (
      <div className="DashboardWidget ActivityGraph">
        <div className="GraphHeader">
          <h4>Global Activity</h4>
          <div className="GraphLabels">
            <div><div className="circle" style={{"backgroundColor": "#45484c"}}></div>Pull Requests</div>
            <div><div className="circle" style={{"backgroundColor": "#febb2a"}}></div>New Files</div>
          </div>
        </div>
        <div className="GraphContainer">
          {entries}
        </div>
        <div className="GraphFooter">
          <div className="UpdateStats">7 updates in last 30 days</div>
        </div>
      </div>
      )
  }
}


class ProjectBreakdown extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let val = (this.props.data != null) ? this.props.data.TotalProjects : "";
    return (<div className="DashboardWidget ProjectBreakdown">
        <PieChart val={val} label="projects"/>
      </div>
      )
  }
}

class DailyCommits extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let val = Math.floor(Math.random()*100)
    return (<div className="DashboardWidget DailyCommits">
      <h4>Avg. Daily Commits</h4>
      <h3>{val}</h3>
      <HorizontalBar val={val} color="#FEBB2B"/>
      </div>
      )
  }
}

class TestCoverage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let val = Math.floor(Math.random()*100)
    return (<div className="DashboardWidget TestCoverage">
      <h4>Test Coverage</h4>
      <h3>{val}</h3>
      <HorizontalBar val={val} color="#C2442C"/>
      </div>
      )
  }
}

class NewReleases extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let val = Math.floor(Math.random()*100)
    return (<div className="DashboardWidget NewReleases">
      <h4>New Releases</h4>
      <h3>{val}</h3>
      <HorizontalBar val={val} color="#75A6C1"/>
      </div>
      )
  }
}

class ActiveDevelopers extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (<div className="DashboardWidget ActiveDevelopers">
      <h4>Active Developers</h4>
      <h3>81</h3>
      <HorizontalBar val={Math.random()*100} color="#7AA77D"/>
      </div>
      )
  }
}


class RecentProjects extends React.Component {

  constructor(props) {
    super(props);
    this.onClick = props.onClick;
  }

  render() {
    let entries = []
    let data = this.props.data ? this.props.data["RecentProjects"] : []
    for (let i=0; i<Math.min(data.length, 4); i++) {
      entries.push(<div className="RecentProjectEntry" onClick={() => this.onClick(data[i]["RepoUrl"])} key={"recent-entry-" + i}>
        <div className="info">
          <h3>{data[i].ProjectName}</h3>
          <p>{data[i].ProjectDescription}</p>
        </div>
        <div className="metadata">
          <div className="LastUpdated">6 hours ago</div>
          <div><div className="LanguageTag">{data[i].Language}</div></div>
        </div>
        </div>)
    }

    return (<div className="DashboardWidget RecentProjects">
      <h4>Recent Projects</h4>
      {entries}
      </div>
      )
  }
}


class LatestCommits extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let colors = ["#c1452c", "#77a9c4", "#7faf83", "#79a77d"]
    let entries = []
    let data = this.props.data ? this.props.data["LatestCommits"] : []
    for (let i=0; i<Math.min(data.length, 4); i++) {
      entries.push(<div className="CommitEntry" key={"commit-entry-" + i}>
                   <div className="CommitMetadata">
                      <div className="circle" style={{"backgroundColor": colors[i%colors.length]}}></div>
                     <h3>{data[i].Author.Name}</h3>
                     <h4>{data[i].Author.When}</h4>
                    </div>
                    <div className="CommitMessage">
                     <h4>{data[i].Message}</h4>
                    </div>
                   </div>
                   );
    }

    return (<div className="DashboardWidget LatestCommits">
      <h4>Latest Commits</h4>
      {entries}
      </div>
      )
  }
}


class ReviewRequests extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let colors = ["#c1452c", "#77a9c4", "#7faf83", "#79a77d"]
    let entries = []
    let data = this.props.data ? this.props.data["LatestCommits"] : []
    let authors = {}
    for (let i=0; i<data.length; i++) {
      if (authors[data[i].Author.Name]) {
        continue
      } 
      authors[data[i].Author.Name] = true
      if (Object.keys(authors).length > 5) {
        break
      }
      let coverage = Math.ceil(100*Math.random())
      entries.push( 
            <div className="ActiveCommiterEntry">
              <div className="flex-holder">
                <div className="InitialsCircle" style={{"backgroundColor": colors[i%colors.length]}}>BS</div>
                <div>
                  <h6>{data[i].Author.Name}</h6>
                  <h4>{coverage + "% coverage"}</h4>
                </div>
              </div>
              <HorizontalBar val={coverage} color={colors[i%colors.length]} height={"8px"}/>
            </div>
        )
    }
    return (<div className="DashboardWidget ReviewRequests">
              <h4>Most Active Commiters</h4>
              {entries}
          </div>
      )
  }

}


class TrendingGithub extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (<div className="DashboardWidget TrendingGithub">
      <h4>Trending on Github</h4>
      <div className="GithubTrendingGrid">

        <div className="TrendingEntry">
          <div className="TrendingEntryRow">
            <h6>googletest</h6>
            <h4>6 hours ago</h4>
          </div>        
          <div className="TrendingEntryRow">
            <h4>Googletest - Google Testing and Mocking Framework</h4>
            <div className="language">C++</div>
          </div>        
        </div>

        <div className="TrendingEntry">
          <div className="TrendingEntryRow">
            <h6>takenote</h6>
            <h4>3 hours ago</h4>
          </div>        
          <div className="TrendingEntryRow">
            <h4>A web-based notes app for developers.</h4>
            <div className="language">Typescript</div>
          </div>        
        </div>

        <div className="TrendingEntry">
          <div className="TrendingEntryRow">
            <h6>pulse</h6>
            <h4>12 hours ago</h4>
          </div>        
          <div className="TrendingEntryRow">
            <h4>PULSE: Self-Supervised Photo Upsampling via Latent Space Exploration of Generative Models</h4>
            <div className="language">Python</div>
          </div>        
        </div>

        <div className="TrendingEntry">
          <div className="TrendingEntryRow">
            <h6>podman</h6>
            <h4>14 hours ago</h4>
          </div>        
          <div className="TrendingEntryRow">
            <h4>Podman: A tool for managing OCI containers and pods</h4>
            <div className="language">Go</div>
          </div>        
        </div>

        <div className="TrendingEntry">
          <div className="TrendingEntryRow">
            <h6>micronaut-core</h6>
            <h4>21 hours ago</h4>
          </div>        
          <div className="TrendingEntryRow">
            <h4>Micronaut Application Framework</h4>
            <div className="language">Java</div>
          </div>        
        </div>

        <div className="TrendingEntry">
          <div className="TrendingEntryRow">
            <h6>html5-boilerplate</h6>
            <h4>15 hours ago</h4>
          </div>        
          <div className="TrendingEntryRow">
            <h4>A professional front-end template for building fast, robust, and adaptable web apps or sites.</h4>
            <div className="language">JavaScript</div>
          </div>        
        </div>

      </div>
      </div>
      )
  }
}

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
    }

    this.projectClick = props.projectClick;
  }


  fetchResults() {
    // console.log(getAPIHostname() + "/dashboard?userid=" + getUserId())
    fetch(getAPIHostname() + "/dashboard?userid=" + getUserId())
        .then(res => res.text())
        .then(res => this.setState({"data": JSON.parse(res)}))
        .catch(err => this.setState({"data": {}}))
  }

  render() {
    if (this.state.data == null) {  // lazy fetch
      this.fetchResults()
    }

    if (this.state.data != null && Object.keys(this.state.data).length > 0) {
      return (<div className="DashboardContainer">
          <div className="Dashboard">
            <ActivityGraph />
            <ProjectBreakdown data={this.state.data} />
            <DailyCommits />
            <TestCoverage />
            <NewReleases />
            <ActiveDevelopers />
            <RecentProjects data={this.state.data} onClick={(val) => this.projectClick(val)}/>
            <LatestCommits data={this.state.data} />
            <ReviewRequests data={this.state.data} />
            <TrendingGithub />
          </div>
        </div>)
    } else {  // unable to fetch data
      return (this.state.data == null ? <EmptyState loader={true}/> : <EmptyState emptyAccount={true}/>)
    }
  }

}


export default Dashboard;