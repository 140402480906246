import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import './SharingModal.css';
import shareIcon from '../img/share-inactive.svg';
import closeIcon from '../img/close.svg';

import { getProxyHostname } from '../util/Utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

class SharingModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: "email",
      permalinkShared: false,
      emailShared: false
    }
  }

  render() {
    return (<div className="SharingModal">
        <div className="ShareScorecard">
          
          <div className="ShareHeader">
            <div className="ShareHeaderTitle">
              <img src={shareIcon}/>
              <h3>Share this Scorecard</h3>
            </div>
            <img src={closeIcon} className="close-icon" onClick={() => this.props.onCloseClick()}/>
          </div>

          <div className="SharingOptions">
            <div className="SharingOption ViaEmail" 
                 onClick={() => this.setState({selected: "email", permalinkShared: false})}
                 style={this.state.selected === "email" ? {"borderBottom": "2px solid #e75b86"} : {}}>
              <h4>Via Email</h4>
            </div>
            <div className="SharingOption ViaPermalink" 
                  onClick={() => this.setState({selected: "permalink", permalinkShared: false})}
                  style={this.state.selected === "permalink" ? {"borderBottom": "2px solid #e75b86"} : {}}>
              <h4>Permalink URL</h4>
            </div>
            <div className="SharingOption OnSlack">
              <h4>On a Slack Channel</h4>
            </div>
          </div>


          {this.state.selected === "email" && 
            <Formik
               initialValues={{ email: '', message: '' }}
               validate={values => {
                 const errors = {};
                 if (!values.email) {
                   errors.email = 'Please enter a valid recipient email';
                 } else if (
                   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                 ) {
                   errors.email = 'Invalid email address';
                 }
                 return errors;
               }}
               onSubmit={(values, { setSubmitting }) => {
                 setTimeout(() => {
                    fetch(getProxyHostname() + "/api/notify?sendto=" + values.email + "&message=" + values.message + "&url=" + encodeURIComponent(this.props.permalink))
                        .then(res => res.text())
                        .then(res => this.props.onCloseClick())
                      .catch(err => console.log(err))
                   setSubmitting(false);
                 }, 400);
               }}
             >
               {({ isSubmitting }) => (
                 <Form>
                   <h5>email address</h5>
                   <Field type="email" name="email" autoComplete="off"/>
                   <ErrorMessage name="email" component="div" />
                   <h5>message</h5>
                    <Field component="textarea" name="message" autoComplete="off" />
                   <ErrorMessage name="message" component="div" />
                   <label>
                     <Field type="checkbox" name="checked" value="Three" />Notify me when the user has opened the shared link
                   </label>
                   <button type="submit" disabled={isSubmitting} className="ShareButton">
                     {isSubmitting && <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
                     }
                     {!isSubmitting && "Share"}
                   </button>                
                 </Form>                 
               )}
             </Formik>
           }

           {this.state.selected === "permalink" && 

           <div className="PermalinkSharing">
             <h5>generated permalink</h5>
             <div className="GeneratedPermalinkContainer">
                  <div className="GeneratedPermalink">{this.props.permalink}</div>
                  <div className="PermalinkShareButton" 
                      onClick={() => {navigator.clipboard.writeText(this.props.permalink); this.setState({permalinkShared: true}) }}
>Copy</div>
              </div>
              <div className="SharingStatus" style={this.state.permalinkShared ? {"display": "block"} : {}}>
                <h6>copied to clipboard!</h6>
              </div>
           </div>

          }

        </div>
      </div>)
  }

}


export default SharingModal;