import React from 'react';

import downIcon from '../img/arrowdown.svg';

class ImportProject extends React.Component {
  render() {
    return (
      <div className="ImportProjectButton" onClick={() => this.props.onClick()} >
        <div>Import Project</div>
        <div className="dash"></div>
        <img src={downIcon} />
      </div>
      )
  }
}

export default ImportProject;